import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobUpdateNotification } from '@core/models/job-updates-notification.model';
import { JobUpdatesState } from '@core/store/job-updates/job-updates.state';
import { FieldWrapper } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { CreateBidState } from 'app/modules/wizard/store/create-bid/create-bid-state';
import { CurrentTenderState } from 'app/modules/wizard/store/current-tender/current-tender.state';
import { WizardState } from 'app/modules/wizard/store/wizard.state';
import { Observable, Subject, Subscription, filter, interval, takeUntil, tap } from 'rxjs';
import { TenderStates } from '../../enums/tender-status.enum';
import { TenderModel } from '../../models/tender.model';
import { TenderService } from '../../services/tender-service';
import { FetchBidsByTenderIdAction } from '../../store/bids/bids.actions';
import { BidState } from '../../store/bids/bids.state';

const COUNTDOWN_INTERVAL = 1000;
const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const HOURS_PER_DAY = 24;

// TODO: Refactor component to be reusable for both tender view and my bids view
@Component({
  selector: 'hmt-tender-summary',
  templateUrl: './tender-summary.component.html',
  styleUrl: './tender-summary.component.scss',
})
export class TenderSummaryComponent extends FieldWrapper implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private countdownSubscription: Subscription;
  private tenderStartDate: Date;
  private tenderEndDate: Date;

  @Input() isMyBidView = false; // TODO: implement a better approach to identify whether its the tender view or my bid view

  tenderId: string;
  jobFileName = { key: 'Job File Name', value: '' };
  tenderReferenceNumber = { key: 'Tender Reference Number', value: '' };
  tenderStatus = { key: 'Tender Status', value: '' };
  jobReferenceNumber = { key: 'Job Reference Number', value: '' };
  tenderStartedAt = { key: 'Tender Started At', value: '' };
  tenderExpiresOn = { key: 'Tender Expires On', value: '' };
  remainingTime = { key: 'Remaining Time', value: '' };
  numberOfBids = { key: 'Number of Bids', value: '' };
  tenderStates = TenderStates;

  store$ = inject(Store);

  jobUpdate$: Observable<JobUpdateNotification> = this.store$.select(JobUpdatesState.getLatestJobUpdates);
  currentTenderStatus$: Observable<TenderStates> = this.store$.select(BidState.getCurrentTenderStatus);
  currentTender$: Observable<TenderModel> = this.store$.select(CurrentTenderState.getTender);

  constructor(
    private tenderService: TenderService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentTender$.pipe(takeUntil(this.destroy$)).subscribe(tender => {
      if (!tender) return;
      this.tenderId = tender._id;
      this.tenderStatus.value = tender.tenderStatus.replaceAll('_', ' ');
      this.tenderStartDate = new Date(tender.tenderTerm.data.validity.validity['from']);
      this.tenderEndDate = new Date(tender.tenderTerm.data.validity.validity['to']);
      this.updateDates();
      this.startCountdown();
      this.listenToTenderUpdates();
      this.tenderReferenceNumber.value = tender.tenderRefNumber;
    });

    // Only used for my view view
    if (this.isMyBidView) {
      this.store$
        .select(CreateBidState.myBidCards)
        .pipe(
          takeUntil(this.destroy$),
          tap(bids => {
            if (!bids) return;
            this.numberOfBids.value = bids.length.toString();
          })
        )
        .subscribe();
    } else {
      this.store$
        .select(BidState.getTotalBidsForTender)
        .pipe(
          takeUntil(this.destroy$),
          tap(totalBids => {
            this.numberOfBids.value = totalBids.toString();
          })
        )
        .subscribe();
    }

    this.store$
      .select(WizardState.getJobFile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(job => {
        if (!job) return;
        this.jobReferenceNumber.value = job.jobRefId;
        this.jobFileName.value = job.title;
      });
  }

  private listenToTenderUpdates() {
    this.jobUpdate$
      .pipe(
        takeUntil(this.destroy$),
        filter((update: JobUpdateNotification) => {
          return update && (update.entityType === 'TENDER' || update.entityType === 'BID');
        }),
        tap((jobUpdate: JobUpdateNotification) => {
          if (jobUpdate.entityType === 'BID' && jobUpdate.data && jobUpdate.data['_id'] === this.tenderId) {
            this.store$.dispatch(new FetchBidsByTenderIdAction(this.tenderId));
          }
          if (jobUpdate.entityType === 'TENDER' && jobUpdate.data && jobUpdate.data['_id'] === this.tenderId) {
            this.tenderService.handleTenderUpdate(jobUpdate, this.tenderId);
          }
        })
      )
      .subscribe();
  }

  private updateDates() {
    this.tenderStartedAt.value = this.formatDate(this.tenderStartDate);
    this.tenderExpiresOn.value = this.formatDate(this.tenderEndDate);
  }

  private formatDate(date: Date): string {
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  }

  private startCountdown() {
    this.countdownSubscription = interval(COUNTDOWN_INTERVAL)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // TODO: Refactor this to be reusable for both tender view and my bids view
        if (
          ![TenderStates.PUBLISHED, TenderStates.IN_PROGRESS, TenderStates.EXPIRED].includes(
            this.store$.selectSnapshot(CurrentTenderState.getTender).tenderStatus
          )
        ) {
          this.stopCountdown();
          this.remainingTime.value = 'Tender Closed';
          this.changeDetectorRef.detectChanges();
          return;
        }
        const now = new Date();
        if (now < this.tenderStartDate) {
          this.remainingTime.value = this.calculateTimeRemaining(now, this.tenderStartDate);
          this.changeDetectorRef.detectChanges();
        } else if (now < this.tenderEndDate) {
          this.remainingTime.value = this.calculateTimeRemaining(now, this.tenderEndDate);
          this.changeDetectorRef.detectChanges();
        } else {
          this.remainingTime.value = 'Tender Expired';
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  private calculateTimeRemaining(from: Date, to: Date): string {
    const diff = to.getTime() - from.getTime();
    const days = Math.floor(diff / (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY));
    const hours = Math.floor(
      (diff % (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY)) /
        (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR)
    );
    const minutes = Math.floor(
      (diff % (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR)) /
        (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE)
    );
    const seconds = Math.floor((diff % (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE)) / MILLISECONDS_PER_SECOND);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  private stopCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
      this.countdownSubscription = null;
    }
  }

  getStatusClass(status: string): string {
    const statusMap = {
      [this.tenderStates.PUBLISHED]: 'status-published',
      [this.tenderStates.EVALUATING]: 'status-evaluating',
      [this.tenderStates.IN_PROGRESS]: 'status-in-progress',
      [this.tenderStates.OFFERED]: 'status-offered',
      [this.tenderStates.AWARDED]: 'status-awarded',
      [this.tenderStates.TERMINATED]: 'status-terminated',
      [this.tenderStates.EXPIRED]: 'status-expired',
    };
    return statusMap[status] || '';
  }

  ngOnDestroy(): void {
    this.stopCountdown();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
